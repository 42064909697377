import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import {
  DataDocs,
  DataNotes,
  DataOverview,
  DataPacket,
  OverviewStep,
  VISAVALUE
} from "../../types/tables-data";
import { ExtractionStatus } from "../extraction-jobs/extractionJobsSlice";

export enum GeneralAddress {
  US = "US",
  FOREIGN = "Foreign"
}
export enum AddressAptSteFloor {
  EMPTY = "",
  APT = "Apt",
  STE = "Ste",
  FLOOR = "Floor"
}

export interface FirstForm {
  addressGeneral: GeneralAddress;
  careOf: string;
  streetNumberName: string;
  addressAptSteFloor: AddressAptSteFloor;
  addressAptSteFloorInput: string;
  cityOrTown: string;
  state: string;
  province: string;
  postalCode: string;
  country: string;
}
export interface SecondForm {
  cityOfBirth: string;
  stateOfBirth: string;
  countryOfBirth: string;
  nationality: string;
  alienNumber: string;
  ssn: string;
  uploadDate: number;
  uploadedByName: string;
  uploadedByUid: string;
}
export interface ExpertLetter {
  uid?: string;
  expertName?: string;
  institution?: string;
  relationship?: string;
  customRelationship?: string;
  expertRole?: string;
  expertCurrent?: string;
  relationshipStartDate?: number | string;
  relationshipEndDate?: number | string;
  isWorkedTogether?: boolean;
  uploadDate?: number;
  uploadedByName: string;
  uploadedByUid: string;
  expertiseSourceList?: string[];
  specificRoleList?: string[];
  abilitiesList?: string[];
  roleImportanceList?: string[];
  resumeUrl?: string;
  filePath?: string;
  summary?: string;
  summaryHtml?: string;
  docNames?: string;
  autoOCRText?: string;
  email?: string;
  phoneNumber?: string;
  sharedOrganization?: string;
  evidenceIds?: string[] | null;
  status?: ExtractionStatus | null;
  created_at?: number | string;
  isDeleted: boolean;
  // Indexable extension for dynamic keys
}

export interface ClientState {
  id: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  name?: string;
  birthDay: number;
  createdDate: number;
  visa: VISAVALUE;
  email: string;
  isActive: boolean;
  address?: string;
  state?: string;
  city?: string;
  zipcode?: string;
  practiceName?: string;
  actionNeeded?: boolean;
  caseId?: number;
  citizenship?: string;
  countryob?: string;
  employer?: string;
  employerPetitioner?: string;
  priority?: string;
  status: number;
  overview: DataOverview;
  notes: DataNotes[];
  documents: DataDocs[];
  packet?: DataPacket[];
  lawyerId: string;
  lawyerEmail?: string;
  lawyerFirstName?: string;
  lawyerLastName?: string;
  questionnaireFirst?: FirstForm;
  questionnaireSecond?: SecondForm;
  questionnaireLetter?: ExpertLetter | null;
  avatarSrc?: string;
  avatarName?: string;
  citizenships?: string[];
  title?: string;
  pronouns?: string;
  otherPronouns?: string;
  phone?: string;
  mailingAddress?: {
    street?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    country?: string;
  };
  physicalAddress?: {
    street?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    country?: string;
  };
  similarAddress?: boolean;
  employerInfo?: {
    id?: string;
    legalBusinessName?: string;
    dba?: string;
    businessAddress: {
      street?: string;
      city?: string;
      state?: string;
      zipCode?: string;
    };
    contactName?: string;
    contactTitle?: string;
  };
  isDeleted?: boolean;
}

export type ClientStateWithNoQuestionnaire = Omit<
  ClientState,
  | "questionnaireFirst"
  | "questionnaireSecond"
  | "questionnaireLetter"
  | "activeExpertLetter"
>;
export type NewCaseFormState = Pick<
  ClientState,
  | "visa"
  | "firstName"
  | "middleName"
  | "lastName"
  | "pronouns"
  | "title"
  | "email"
  | "employerPetitioner"
  | "employerInfo"
>;
export const initialNewCaseFormState: NewCaseFormState = {
  email: "",
  firstName: "",
  lastName: "",
  visa: VISAVALUE.EMPTY,
  pronouns: "",
  title: ""
};
export const initialClientState: ClientStateWithNoQuestionnaire = {
  id: "",
  firstName: "",
  middleName: "",
  lastName: "",
  name: "",
  birthDay: 0, // Default as Unix epoch or appropriate default
  createdDate: 0, // Default as Unix epoch or appropriate default
  visa: VISAVALUE.EMPTY, // Example default, ensure this is a valid VISAVALUE
  email: "",
  isActive: false,
  address: "",
  state: "",
  city: "",
  zipcode: "",
  practiceName: "",
  actionNeeded: false,
  caseId: 0,
  citizenship: "",
  countryob: "",
  employer: "",
  priority: "",
  status: 0,
  overview: {} as DataOverview, // Initialize with empty defaults
  notes: [],
  documents: [],
  packet: [],
  lawyerId: "",
  lawyerEmail: "",
  lawyerFirstName: "",
  lawyerLastName: "",
  avatarSrc: "",
  avatarName: "",
  citizenships: [],
  title: "",
  pronouns: "",
  otherPronouns: "",
  phone: "",
  mailingAddress: {
    street: "",
    city: "",
    state: "",
    zipCode: "",
    country: ""
  },
  physicalAddress: {
    street: "",
    city: "",
    state: "",
    zipCode: "",
    country: ""
  },
  similarAddress: false,
  employerInfo: {
    legalBusinessName: "",
    dba: "",
    businessAddress: {
      street: "",
      city: "",
      state: "",
      zipCode: ""
    },
    contactName: "",
    contactTitle: ""
  }
};

export const defaultEmployerInfo = {
  businessAddress: { city: "", state: "", street: "", zipCode: "" },
  contactName: "",
  contactTitle: "",
  dba: "",
  legalBusinessName: ""
};

const initialState: ClientState[] = [];

export type ClientContactInfo = Pick<
  ClientState,
  "email" | "phone" | "mailingAddress" | "physicalAddress"
>;

export type ClientEmployerInfo = Pick<ClientState, "employerInfo" | "id">;
// export type ClientEmployerInfo = ClientState["employerInfo"];

export interface ClientsReducer {
  clients: ClientState[];
}
export interface AddOverviewStepsData {
  steps: OverviewStep[];
  index?: number;
}
export interface ArchiveCase {
  isActive: boolean;
  index: number;
}

export interface UpdateClientCaseData {
  firstName: string;
  middleName?: string;
  lastName: string;
  birthDay: number;
  citizenship: string;
  countryob: string;
  employer: string;
  address: string;
  state?: string;
  city?: string;
  zipcode?: string;
  visa: VISAVALUE;
  email: string;
}
export interface UpdateClientCase {
  data: UpdateClientCaseData;
  index: number;
}
export interface UpdateOverviewStep {
  stepIndex: number;
  index?: number;
  value: boolean;
}
export interface AddNotesData {
  note: DataNotes;
  index: number;
}
export interface SetNewNotesData {
  notes: DataNotes[];
  index: number;
}
export interface SetExpertLetter {
  questionnaireLetter: ExpertLetter | null;
  index: number;
}

export const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    resetClientsData: () => initialState,
    setActiveClientsData: (state, action: PayloadAction<ClientState[]>) =>
      action.payload,
    addNewActiveClient: (state, action: PayloadAction<ClientState>) => {
      state.push(action.payload);
    },
    addOverviewStepsToClient: (
      state,
      { payload }: PayloadAction<AddOverviewStepsData>
    ) => {
      if (payload.index) state[payload.index].overview.steps = payload.steps;
    },
    updateOverviewStep: (
      state,
      { payload }: PayloadAction<UpdateOverviewStep>
    ) => {
      if (payload.index) {
        state[payload.index].overview.steps[payload.stepIndex].isComplete =
          payload.value;
        state[payload.index].status = state[
          payload.index
        ].overview.steps.reduce((acc, step) => {
          if (step.isComplete) {
            acc += 1;
          }
          return acc;
        }, 1);
      }
    },
    addNewNote: (state, { payload }: PayloadAction<AddNotesData>) => {
      state[payload.index].notes.push(payload.note);
    },
    setNewNotes: (state, { payload }: PayloadAction<SetNewNotesData>) => {
      state[payload.index].notes = payload.notes;
    },
    archiveClientCase: (state, { payload }: PayloadAction<ArchiveCase>) => {
      state[payload.index].isActive = payload.isActive;
    },
    updateClientCaseData: (
      state,
      { payload }: PayloadAction<Partial<ClientState>>
    ) => {
      const index = state.findIndex((element) => element.id === payload.id);
      const client = state[index];
      const updatedClient = { ...client, ...payload };
      console.log("updated one", updatedClient);

      state[index] = updatedClient;
    },
    deleteCase: (state, { payload }: PayloadAction<string | number>) => {
      const caseId = payload;
      const caseIndex = state.findIndex(
        (individual) => individual.id === caseId
      );
      state[caseIndex].isDeleted = true;
    },
    updateQuestionnaireLetter: (
      state,
      { payload }: PayloadAction<SetExpertLetter>
    ) => {
      state[payload.index].questionnaireLetter = payload.questionnaireLetter;
    }
  }
});

export const {
  resetClientsData,
  setActiveClientsData,
  addNewActiveClient,
  addOverviewStepsToClient,
  addNewNote,
  setNewNotes,
  updateOverviewStep,
  archiveClientCase,
  updateClientCaseData,
  deleteCase,
  updateQuestionnaireLetter
} = clientsSlice.actions;

export default clientsSlice.reducer;
