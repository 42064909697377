import {
  Box,
  Button,
  Flex,
  SkeletonText,
  useToast
} from "@chakra-ui/react";
import { debounce } from "lodash"; // Import lodash's debounce function
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "react";
import ReactQuill, { Quill } from "react-quill";

import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import { convertFromHtml } from "../../api/draftsApi";
import { addCustomPrompt } from "../../api/OpenAIApi";
import { triggerDownload } from "../../helpers/file_helpers";
import { useNavbar } from "../../hooks/useNavbarContext";
import "../../styles/reactquill.css";
import { AIToolbar } from "./AiToolbar";
import { ChangingText } from "./ChangingTextClass";
import { CustomHighlight } from "./CustomHighlightClass";
import { RichTextToolbar } from "./RichTextToolbar";

const PROMPT_CHARS_LIMIT = 10000;

Quill.register(CustomHighlight, true);
Quill.register(ChangingText, true);

type RichTextProps = {
  text: string;
  saveText: null | ((html: string, plainText: string) => void);
  updateText?: (html: string, plainText?: string) => void;
  shouldShowSaveBtn?: boolean | null;
  shouldPreserveWhitespace?: boolean;
  placeholder?: string | null;
  customHeight?: string;
  editorId?: string;
  loading?: boolean | null;
};

export const RichTextEditor = ({
  text,
  saveText,
  updateText = () => {},
  shouldShowSaveBtn = true,
  shouldPreserveWhitespace = false,
  placeholder,
  customHeight = "80vh",
  editorId = "",
  loading = false
}: RichTextProps) => {
  const { id: uid } = useParams();
  const [parsedText, setParsedText] = useState<string>(text || "");
  const [selectedText, setSelectedText] = useState<undefined | string>(
    undefined
  );
  const previousRangeRef = useRef<any>(null);
  const [parsedTextCopy, setParsedTextCopy] = useState<string>("");
  const [range, setRange] = useState<null | ReactQuill.Range>(null);
  const [bounds, setBounds] = useState<any | null>(null);
  const [quillToolbarHeight, setQuillToolbarHeight] = useState(0);

  const [showToolbar, setShowToolbar] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const quillRef = useRef<ReactQuill>(null);
  const [isPromptSelected, setIsPromptSelected] = useState<boolean | null>(
    false
  );

  const { setPos } = useNavbar();
  const toast = useToast();

  const [changingText, setChangingText] = useState(false);
  const [allowOverflow, setAllowOverflow] = useState(false);
  const handleScroll = () => {
    if (range && quillRef.current) {
      const editor = quillRef.current.getEditor();
      const newBounds = editor.getBounds(range.index, range.length);
      setBounds(newBounds);
    }
  };

  useEffect(() => {
    setPos("relative");
    return () => {
      setPos("sticky");
    };
  }, []);

  useEffect(() => {
    const editorContainer = quillRef.current?.getEditor()?.root;
    editorContainer?.addEventListener("scroll", handleScroll, {
      passive: true
    });
    return () => editorContainer?.removeEventListener("scroll", handleScroll);
  }, [range]);

  useLayoutEffect(() => {
    const toolbar = document.querySelector(".ql-toolbar");
    if (toolbar) {
      setQuillToolbarHeight(toolbar.clientHeight);
    }
  }, [range]);

  const getText = () => {
    const editor = quillRef?.current?.getEditor(); // access the Quill editor
    const text = editor?.getText(); // get plain text, without HTML
    return text;
  };

  useEffect(() => {
    if (parsedTextCopy === "") {
      setParsedTextCopy(parsedText);
    } else if (parsedTextCopy !== parsedText) {
      setShouldUpdate(true);
      updateText(parsedText, getText() ?? "");
    } else {
      setShouldUpdate(false);
    }
  }, [parsedText]);

  // Applied a fix for cases where the user pastes text, as Quill's onChange event wasn't triggered.
  // Debounce the text change handler
  const handleTextChange = useCallback(
    debounce(() => {
      const editor = quillRef?.current?.getEditor();
      if (editor) {
        const text = editor.getText();
        setParsedText(editor.root.innerHTML);
        updateText(editor.root.innerHTML, text);
      }
    }, 300), // Delay of 300ms after user stops typing
    []
  );

  const handleSelectionChange = (range: any, oldRange: any, source: any) => {
    if (isPromptSelected) {
      // Skip handling if prompt input is active
      return;
    }
  
    const editor = quillRef?.current?.getEditor();
  
    if (!range || range.length === 0) {
      if (previousRangeRef.current) {
        editor?.formatText(
          previousRangeRef.current.index,
          previousRangeRef.current.length,
          "customHighlight",
          false
        );
      }
      setShowToolbar(false);
      setSelectedText(undefined);
      setRange(null);
      previousRangeRef.current = null;
      return;
    }
  
    if (editor) {
      if (previousRangeRef.current) {
        editor.formatText(
          previousRangeRef.current.index,
          previousRangeRef.current.length,
          "customHighlight",
          false
        );
      }
  
      const selectionBounds = editor.getBounds(range.index, range.length);
      const selectedText = editor.getText(range.index, range.length);
      setSelectedText(selectedText);
      setBounds(selectionBounds);
      setRange(range);
      setShowToolbar(true);
      editor.formatText(range.index, range.length, "customHighlight", true);
      previousRangeRef.current = range;
    }
  };
  
  
  useEffect(() => {
    const editor = quillRef?.current?.getEditor();
    if (editor) {
      editor.on("text-change", handleTextChange);
    }

    return () => {
      if (editor) {
        editor.off("text-change", handleTextChange);
      }
    };
  }, [handleTextChange]);

  const handleDownload = async (format: string) => {
    setIsDownloading(true);
    try {
      const { data, filename } = await convertFromHtml(format, parsedText, "");
      triggerDownload(data, filename, format);
    } catch (error) {
      console.error(`Error generating ${format} file:`, error);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleSave = () => {
    const plainText = getText() ?? "";
    if (saveText !== null) {
      saveText(parsedText, plainText);
      setShouldUpdate(false);
      toast({
        title: "Text saved.",
        description: "Your changes have been saved successfully.",
        status: "info",
        duration: 3000,
        isClosable: true,
        position: "bottom-right"
      });
    }
  };

  useEffect(() => {
    setParsedText(text ?? "");
  }, [text]);

  const handleAIButtonClick = async (
    rewriteOption?: string,
    toneOption?: string,
    customPrompt?: string
  ) => {
    const quillEditor = quillRef?.current?.getEditor();
    const fullText = quillEditor?.getText();
    if (quillEditor) {
      if (selectedText && range && fullText) {
        if (selectedText.length > PROMPT_CHARS_LIMIT) {
          toast({
            status: "warning",
            colorScheme: "red",
            title: "The text selected is too long.",
            description:
              "Please shorten the selected text to a maximum of 4 pages."
          });
          return;
        }

        if (selectedText && range && fullText) {
          setChangingText(true);

          // TODO implement ui skeleton and fix spacing issue
          // this bug took me sometime and couldn't solve it

          // quillEditor.deleteText(selection.index, selection.length);
          // quillEditor.insertEmbed(
          //   selection.index,
          //   "html",
          //   `<p class="loading-text">${selectedText}</p>`
          // );

          const result = await addCustomPrompt(
            uid!,
            selectedText,
            fullText,
            rewriteOption,
            toneOption,
            customPrompt
          );
          quillEditor.deleteText(range.index, range.length);
          quillEditor.insertText(range.index, result.updatedText);
          quillEditor.formatText(
            range.index,
            result.updatedText.length,
            "customHighlight",
            true
          );
          previousRangeRef.current = {
            index: range.index,
            length: result.updatedText.length
          };
          setChangingText((prev) => !prev);
          setShowToolbar(false);
        }
      }
    }
  };
  return (
    <>
      <Box
        position="relative"
        height="100%"
        width="100%"
        overflow="hidden"
        wordBreak="break-word"
      >
        <RichTextToolbar
          handleDownload={handleDownload}
          isDownloading={isDownloading}
          quillRef={quillRef.current}
          editorId={editorId}
        />
        {/* ReactQuill Editor */}
        <Box
          position="relative"
          border={loading ? "1px solid" : undefined}
          borderColor={loading ? "gray.300" : undefined}
          borderRadius="md"
          backgroundColor="white"
        >
          <ReactQuill
            id={`quill-editor-${editorId}`}
            placeholder={placeholder ?? ""}
            ref={quillRef}
            theme="snow"
            value={loading ? "" : parsedText} // Text disappears when loading
            onChange={(value) => setParsedText(value)}
            readOnly={loading ?? false} // Prevent user interaction during loading
            onBlur={(previous, source, quill) => {
              if (isPromptSelected) return;
              const editor = quillRef.current?.getEditor();
              if (editor && previousRangeRef.current) {
                editor.formatText(
                  previousRangeRef.current.index,
                  previousRangeRef.current.length,
                  "customHighlight",
                  false
                );
                setShowToolbar(false);
              }
            }}
            style={{
              height: customHeight
            }}
            modules={{
              toolbar: { container: `#toolbar-${editorId}` }
            }}
            onChangeSelection={handleSelectionChange}

          />

          {/* Custom Skeleton Loader */}
          {loading && (
            <Box
              position="absolute"
              top={0}
              left={0}
              width="100%"
              height="100%"
              backgroundColor="rgba(255, 255, 255, 0.8)"
              zIndex={1}
              display="flex"
              flexDirection="column"
              padding="10px"
              gap="10px"
            >
              <SkeletonText
                mt={6}
                width="62vw"
                noOfLines={8}
                spacing="18px"
                skeletonHeight="12px"
              />
            </Box>
          )}
        </Box>

        {bounds && (
        <AIToolbar
          bounds={bounds}
          quillToolbarHeight={quillToolbarHeight}
          setSelectedText={setSelectedText}
          selectedRange={range}
          showToolbar={showToolbar}
          changingText={changingText}
          quillRef={quillRef}
          handleAIButtonClick={handleAIButtonClick}
          setAllowOverflow={setAllowOverflow}
          setPromptisSelected={setIsPromptSelected}
        />
      )}
      </Box>

      {shouldUpdate && shouldShowSaveBtn && (
        <Flex justifyContent="center" gap={8} mt="8">
          <Button
            variant="secondaryOutline"
            onClick={() => {
              setParsedText(parsedTextCopy);
            }}
          >
            Reset
          </Button>
          <Button variant="primaryFilled" mr="2" onClick={handleSave}>
            Save
          </Button>
        </Flex>
      )}
    </>
  );
};
