// this code is following chakra styling conventions
// make sure to read the documents to understand
// the anatomy approach
// https://chakra-ui.com/docs/styled-system/component-style

import { selectAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  selectAnatomy.keys
);

const cyan = defineStyle({
  field: {
    rounded: "md",
    background: "#DFF1FB",
    textColor: "black"
  },
  icon: {
    color: "black"
  }
});

export const selectTheme = defineMultiStyleConfig({
  // define the part you're going to style

  baseStyle: {
    field: {
      cursor: "pointer",
      color: "primary.blue",
      _hover: {
        bg: "primary.focusOutline",
        color: "primary.blue"
      },
      _active: {
        bg: "primary.focusOutline",
        color: "primary.blue"
      },
      _disabled: {
        borderColor: "primary.lightGray",
        color: "primary.lightGray",
        cursor: "not-allowed",
        opacity: "0.5"
      }
    }
  },
  variants: {
    cyan
  }
});
