import { Box, Flex, Spacer, Text, IconButton } from "@chakra-ui/react";
import { CloseIcon, WarningTwoIcon } from "@chakra-ui/icons";
import { useEffect, useMemo, useState } from "react";
import { Link as LinkDom, useLocation } from "react-router-dom";
import { auth } from "../api/firebaseApi";
import { firmConfiguration } from "../constans";
import { getSubdomain, setupIntercom } from "../helpers/helpers";
import { useGetUserDetails } from "../hooks/useGetUserDetails";
import styles from "../styles/header.module.css";
import AdminUpdatesModal from "./common/AdminUpdatesModal";
import ExtractionStatusDialog from "./common/ExtractionStatusModal";
import { NavBarLogo } from "./NavbarLogo";
import { UserMenu } from "./UserMenu";
import useAdminUpdates from "../hooks/admin/useManageAdminUpdates";
import { AdminUpdate } from "../pages/Admin/Updates/AdminUpdate";

interface HeaderProps {
  link?: string;
}

export const Header = ({ link }: HeaderProps) => {
  const location = useLocation();

  const {
    userDetails: { id, userType, email }
  } = useGetUserDetails();
  const { adminUpdates, fetchAdminUpdates } = useAdminUpdates();

  const [closedUpdates, setClosedUpdates] = useState<string[]>(() => {
    const stored = localStorage.getItem("closedCriticalUpdates");
    return stored ? JSON.parse(stored) : [];
  });

  const isIndividual = userType === "Individual";

  const [isAuthChecked, setIsAuthChecked] = useState(false);

  useEffect(() => {
    setupIntercom(email, id);
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user && location.pathname !== "/login") setIsAuthChecked(true);
    });

    return () => unsubscribe();
  }, []);

  const logoLink = useMemo(() => {
    if (link) {
      return link;
    }
    if (!isIndividual) {
      return "/cases";
    }
    return "/";
  }, [auth, id]);

  const subdomain = getSubdomain();
  const firmConfig = firmConfiguration[subdomain];

  // Filter out updates that have been closed
  const criticalUpdates = adminUpdates.filter(
    (update: AdminUpdate) =>
      update.priority === "critical" &&
      !closedUpdates.includes(update?.id || "")
  );

  // We'll only show the first available critical update
  const currentCriticalUpdate =
    criticalUpdates.length > 0 ? criticalUpdates[0] : null;

  const handleCloseUpdate = (updateId?: string) => {
    if (!updateId) return;

    const updatedClosed = [...closedUpdates, updateId];
    localStorage.setItem(
      "closedCriticalUpdates",
      JSON.stringify(updatedClosed)
    );
    setClosedUpdates(updatedClosed);
  };

  useEffect(() => {
    fetchAdminUpdates();
  }, []);

  return (
    <header className={styles.header} style={firmConfig?.cssForHeader}>
      <Flex alignItems="center" py="8px" px="20px">
        <Flex alignItems="center" ml="15px">
          <Text fontWeight={500}>
            <LinkDom to={logoLink} style={{ margin: "0", padding: "0" }} />
          </Text>
          <Box>
            <LinkDom
              to={firmConfig?.domainUrl || logoLink}
              target={firmConfig?.domainUrl ? "_blank" : "_self"}
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0",
                padding: "0"
              }}
            >
              <NavBarLogo />{" "}
            </LinkDom>
          </Box>
        </Flex>
        <Spacer />
        <Flex alignItems="center" px={4}>
          {isAuthChecked && (
            <Flex gap={4} className="max-h-10">
              {userType !== "Individual" && <AdminUpdatesModal />}
              <ExtractionStatusDialog />
              <UserMenu />
            </Flex>
          )}
        </Flex>
      </Flex>

      {currentCriticalUpdate && (
        <Flex
          alignItems="center"
          bg="red.500"
          p={3}
          color="white"
          textAlign="start"
          position="relative"
          boxShadow="md"
        >
          {/* Close button in top-right corner */}
          <IconButton
            aria-label="Close update"
            icon={<CloseIcon />}
            variant="ghost"
            color="white"
            position="absolute"
            top="4px"
            right="8px"
            onClick={() => handleCloseUpdate(currentCriticalUpdate?.id)}
          />

          {/* Warning Icon */}
          <WarningTwoIcon boxSize={5} mr={3} />

          {/* Text Content */}
          <Box>
            {/* Title */}
            <Text fontSize="md" fontWeight="bold">
              {currentCriticalUpdate.title}
            </Text>

            {/* Description */}
            {currentCriticalUpdate.description && (
              <Text fontSize="sm" mt={1}>
                {currentCriticalUpdate.description}
              </Text>
            )}
          </Box>
        </Flex>
      )}
    </header>
  );
};
