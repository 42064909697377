import { Box, Select } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SectionWrapper } from "../components/common/SectionWrapper";
import { SpecialHeading } from "../components/individualTabs/PanelLayout";
import { GroupedDocument } from "../helpers/helpers";
import { documentSelectors } from "../redux/documents/selectors";
import { VisaDocumentType } from "../redux/documents/types";
import { DataDocs } from "../types/tables-data";
import { DocumentsSlider } from "./DocumentSlider";
import { NoDocumentsState } from "./individualExtractedEvidence";

export const IndividualExtractedInfo = () => {
  const navigate = useNavigate();
  const { id, visaType } = useParams();

  const standardDocuments = useSelector(documentSelectors.newStandardDocs);

  function groupDocumentsByType(documents: DataDocs[]): GroupedDocument[] {
    const groups: Map<string, DataDocs[]> = new Map();

    documents.forEach((doc) => {
      const type = doc.criterion || "Unknown";
      if (!groups.has(type)) {
        groups.set(type, []);
      }
      groups.get(type)?.push(doc);
    });

    const groupedDocuments = Array.from(groups).map<GroupedDocument>(
      ([key, value], index) => ({
        type: key,
        index,
        subrows: value,
        expanded: false
      })
    );

    return groupedDocuments;
  }

  const groupedStandardDocuments = groupDocumentsByType(standardDocuments);

  const [categoryIndex, setCategoryIndex] = useState(0);

  const [selectedDocument, setSelectedDocument] = useState<
    DataDocs | undefined
  >(undefined);

  useEffect(() => {
    if (standardDocuments && standardDocuments.length > 0) {
      setSelectedDocument(standardDocuments[0]);
    }
  }, []);

  return (
    <SectionWrapper 
    backTargetPage= "Documents"
    onClick={() => navigate(`/individual/${id}/${visaType}/documents/standard-documents`)}>
      <SpecialHeading
        title="Edit Document Details"
        topTitle="standard documents"
      />
      {groupedStandardDocuments.length === 0 ? (
        <NoDocumentsState str="standard" />
      ) : (
        <Box padding={0} className="flex flex-col gap-12">
          <Select
            variant="cyan"
            onChange={(e) => {
              const indx = Number(e.target.value);
              setCategoryIndex(indx);

            }}
            value={categoryIndex}
            width="fit-content"
          >
            {groupedStandardDocuments.map((group, indx) => (
              <option key={indx} value={indx}>
                {indx + 1}. {group.type}
              </option>
            ))}
          </Select>

          <DocumentsSlider
            documentType={VisaDocumentType.Standard}
            categoryIndex={categoryIndex}
            documents={groupedStandardDocuments[categoryIndex]?.subrows ?? []}
          />
        </Box>
      )}
    </SectionWrapper>
  );
};
