import { tableAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tableAnatomy.keys
);

export const tablesTheme = defineMultiStyleConfig({
  // Define the parts you're going to style
  baseStyle: {
    table: {
      borderRadius: "md", // Apply rounded corners to the entire table
      border: "1px solid", // Consistent border around the table
      borderColor: "gray.200", // Adjust border color as per your theme
      overflow: "hidden"
    },
    thead: {
      bg: "primary.backgroundLight",
      borderTopRadius: "md" // Rounded top for the table head
    },
    tbody: {
      tr: {
        borderBottom: "1px solid", // Border between rows
        borderColor: "gray.200",
        background: "white",
        fontWeight: "500"
      },
      td: {
        borderBottom: "1px solid", // Bottom border for table cells
        borderColor: "gray.200"
        // paddingInline: "4px",
        // paddingBlock: "8px"
      }
      // padding: 0
    }
  },
  variants: {
    stripedHover: {
      table: {
        borderRadius: "md",
        border: "1px solid",
        borderColor: "gray.200",
        overflow: "hidden"
      },
      tbody: {
        tr: {
          borderBottom: "1px solid", // Border between rows
          borderColor: "gray.200",
          fontWeight: "500",
          "&:nth-of-type(odd)": {
            background: "white" // Background for odd rows
          },
          "&:nth-of-type(even)": {
            background: "#EEF2F666" // Background for even rows
          },
          _hover: {
            background: "#D1D5DB99" // Darker hover color for even rows
          }
        }
      }
    }
  }
});
