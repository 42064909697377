import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from "@chakra-ui/react";

const CustomDropdown = ({
  options,
  casesNumber,
  currentValue,
  onChange
}: any) => {
  return (
    <Menu autoSelect={false}>
      <MenuButton
        borderRadius="8px"
        variant="neutralStateButton"
        as={Button}
        rightIcon={<ChevronDownIcon />}
        border="1px solid"
        borderColor="gray.300"
        minW="auto"
      >
        {currentValue}
      </MenuButton>

      <MenuList minW="auto">
        {options.map((pageSize: any) => (
          <MenuItem
            px={12}
            key={pageSize}
            value={pageSize}
            onClick={() => onChange(pageSize)}
            backgroundColor={currentValue === pageSize ? "blue.100" : "white"}
            _hover={{
              backgroundColor: "blue.50"
            }}
            _focus={{
              backgroundColor: "blue.100"
            }}
            isDisabled={pageSize > casesNumber}
          >
            <Text
              color={currentValue === pageSize ? "blue.600" : "black"}
              textAlign="center"
              mx="auto"
            >
              {pageSize}
            </Text>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default CustomDropdown;
