/* eslint-disable no-nested-ternary */
import {
  CircularProgress,
  Flex,
  Heading,
  Select,
  Text
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { SectionWrapper } from "../components/common/SectionWrapper";
import { SpecialHeading } from "../components/individualTabs/PanelLayout";
import useGroupedEvidenceDocuments from "../hooks/useGroupedEvidenceDocuments";
import { VisaDocumentType } from "../redux/documents/types";
import { DocumentsSlider } from "./DocumentSlider";

export interface TextChange {
  current: string;
  original: string;
}

const LoadingState = () => (
  <Flex justify="center" align="center" h="100%">
    <CircularProgress isIndeterminate />
  </Flex>
);

export const NoDocumentsState = ({ str }: any) => (
  <Flex direction="column" align="center" justify="center" h="50%">
    <Heading size="md" mb={2}>
      No Documents Available
    </Heading>
    <Text textAlign="center" fontSize="sm">
      It looks like there are no {str} documents uploaded at the moment.
      <br />
      Please upload documents to start reviewing and extracting information.
    </Text>
  </Flex>
);

export const IndividualExtractedEvidence = () => {
  const navigate = useNavigate();
  const { id, visaType } = useParams();
  
  const { groupedDocuments, isLoadingGetEvidence } =
    useGroupedEvidenceDocuments();
  const [categoryIndex, setCategoryIndex] = useState(0);

  return (
    <SectionWrapper 
    backTargetPage= "Documents"
    onClick={() => navigate(`/individual/${id}/${visaType}/documents/evidence-documents`)}>
      <SpecialHeading
        title="Edit Document Details"
        topTitle="evidence documents"
      />

      {isLoadingGetEvidence || groupedDocuments === null ? (
        <LoadingState />
      ) : groupedDocuments.length > 0 ? (
        <div className="flex flex-col justify-center">
          <Select
            variant="cyan"
            onChange={(e) => {
              const indx = Number(e.target.value);
              setCategoryIndex(indx);
            }}
            value={categoryIndex}
            width="fit-content"
            my={8}
          >
            {groupedDocuments.map((group, indx) => (
              <option key={indx} value={indx}>
                {indx + 1}. {group.type}
              </option>
            ))}
          </Select>

          <DocumentsSlider
            documentType={VisaDocumentType.Evidence}
            categoryIndex={categoryIndex}
            documents={groupedDocuments[categoryIndex]?.subrows ?? []}
          />
        </div>
      ) : (
        <NoDocumentsState str="evidence" />
      )}
    </SectionWrapper>
  );
};
